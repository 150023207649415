<template>
  <div class="ossurl">
    <div class="banner">
      <img :src="ossurl+form.pichttp"
           alt=""
           class="mainpic">
    </div>
    <div class="content">
      <div class="item">
        <span class="label">活动名称：</span>
        <span>{{ form.stitle }}</span>
      </div>
      <div class="item">
        <span class="label">活动时间：</span>
        <span>{{ form.fbtime }}</span>
      </div>
      <div class="item">
        <span class="label">活动地点：</span>
        <span>{{ form.conthttp }}</span>
      </div>
      <div class="item">
        <span class="label">联系人：</span>
        <span>{{ form.autor }}</span>
      </div>
      <div class="item">
        <span class="label">联系人电话：</span>
        <span>{{ form.remark }}</span>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <span class="label">活动描述：</span>
        <pre class="contwords">{{form.sdesc}}</pre>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <span class="label">活动说明：</span>
        <pre class="contwords">{{form.smark}}</pre>
      </div>
    </div>
    <div class="myApplyMsg"
         v-show="isApplyed">
      <div class="myMsgTitle">我的报名信息</div>
      <div class="content">
        <div class="item">
          <span class="label">姓名：</span>
          <span>{{ applylist.name }}</span>
        </div>
        <div class="item">
          <span class="label">联系电话：</span>
          <span>{{ applylist.mobile }}</span>
        </div>
        <div class="item">
          <span class="label">参与人数：</span>
          <span>{{ applylist.rennum }}</span>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="applyBtn"
           :style="`backgroundColor:${colorprimary}`"
           @click="applyjoin"
           v-if="user.empno != empno">报名参加</div>
      <div class="applyBtn"
           :style="`backgroundColor:${colorprimary}`"
           @click="applymsg"
           v-else>报名清单</div>
    </div>
    <div class="transmit"
         @click="shareCard">
      <img alt=""
           :src="getStorage('ossurl','')+getStorage('imgs',{}).zficon" />
    </div>
    <md-dialog title="报名信息"
               :closable="true"
               class="applyDialog"
               v-model="isApplyjoinShow">
      <div>
        <div class="applyItem">
          <span><span class="red">*</span> 姓名：</span>
          <input v-model="applylist.name"
                 placeholder="你的姓名" />
        </div>
        <div class="applyItem">
          <span><span class="red">*</span> 联系电话：</span>
          <input v-model="applylist.mobile"
                 maxlength="11"
                 type="text"
                 oninput="value=value.replace(/[^\d]/g,'')"
                 placeholder="你的电话" />
        </div>
        <div class="applyItem">
          <span><span class="red">*</span> 参与人数：</span>
          <input v-model="applylist.rennum"
                 placeholder="参与人数"
                 type="text"
                 oninput="value=value.replace(/[^\d]/g,'')"
                 maxlength="3" />
        </div>
        <div class="dialogbottom">
          <div class="dialogBtn"
           :style="`backgroundColor:${colorprimary}`"
               @click="addApply">确定</div>
        </div>
      </div>
    </md-dialog>
    <div class="share"
         @click="cancelShare"
         v-show="isShareCard">
      <img src="../../../../assets/abt/img/fxbg.png" />
    </div>
  </div>
</template>
<script>
import { selectOneBySno, DoRecord } from '@/api/abt/customerOperation/common'
import { add } from '@/api/abt/customerOperation/invitationletter'
import { getStorage } from '@/lib/util'
import initWebSocket from '@/mixins/websock'
import { wechatshare } from '@/lib/wechat_share'
import config from '@/config'
import { Toast } from 'mand-mobile'
export default {
  mixins: [initWebSocket],
  data () {
    return {
      websock: null,
      height: '',
      ossurl: '',
      user: '',
      suserid: '',
      empno: '',
      form: {
        osno: '',
        stitle: '',
        smark: '',
        sdesc: '',
        pichttp: '',
        fbtime: '',
        conthttp: '',
        autor: '',
        remark: ''
      },
      sno: '',
      isApplyjoinShow: false,
      applylist: {
        name: '',
        mobile: '',
        rennum: ''
      },
      isApplyed: false,
      isShareCard: false,
      colorprimary: ''
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.sno = this.$route.query.sno
    this.empno = this.$route.query.empno
    this.suserid = this.$route.query.suserid
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.getData()
  },
  mounted () {
    document.querySelector('.ossurl').style.height = `${this.height - 50}px`
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
  },
  destroyed: function () {
    this.websocketclose() // 页面销毁时关闭websocket连接
  },
  methods: {
    getData () {
      selectOneBySno({ btagcode: '5', sno: this.sno, flag: '1' }).then(res => {
        this.form = res.data.data
        if (res.data.data.abtComKjYqhSignup) {
          this.applylist = res.data.data.abtComKjYqhSignup
          this.isApplyed = true
        }
        this.behaviorRecord()
        let shareurl = `${config.redirect_uri}/invitationletter/invite?sno=${this.sno}&empno=${this.empno}&suserid=${this.user.userid}&rytype=${this.rytype}&type=5`
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: 2,
          btagcode: 5,
          stagcode: this.form.stagcode,
          sno: this.form.sno,
          osno: this.form.osno,
          rytype: this.rytype
        }
        let path = ''
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          if (this.$route.query.frompage == 'source') {
            path = config.redirect_uri + '/source'
          } else {
            path = config.redirect_uri
          }
        } else {
          // android
          path = window.location.href
        }
        wechatshare(
          `${this.form.autor}邀请您参加${this.form.stitle}`,
          this.form.sdesc,
          this.ossurl + this.form.pichttp,
          shareurl,
          path,
          fxstr
        )
      })
    },
    // 行为记录
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '5',
        stagcode: this.form.stagcode,
        sno: this.form.sno,
        osno: this.form.osno,
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    },
    applymsg () {
      this.$router.push(
        { name: 'applymsg', query: { sno: this.sno, empno: this.empno, suserid: this.user.userid } }
      )
    },
    applyjoin () {
      if (!this.isApplyed) {
        this.isApplyjoinShow = true
      } else {
        Toast.info('您已经报名成功！')
      }
    },
    addApply () {
      if (!this.applylist.name) {
        Toast.info('请输入姓名')
        return
      }
      if (!this.applylist.mobile) {
        Toast.info('请输入电话')
        return
      }
      if (!this.applylist.rennum) {
        Toast.info('请输入参加人数')
        return
      }
      let data = {
        buserid: this.user.userid,
        // buserid: '10000044',
        sno: this.sno,
        name: this.applylist.name,
        mobile: this.applylist.mobile,
        rennum: this.applylist.rennum,
        suserid: this.suserid,
        stitle: this.form.stitle,
        rytype: this.rytype,
        empno: this.empno
      }
      add(data).then(res => {
        Toast.succeed('添加成功')
        this.isApplyed = true
        this.isApplyjoinShow = false
      })
    },
    shareCard () {
      this.isShareCard = true
    },
    cancelShare () {
      this.isShareCard = false
    }
  }
}
</script>
<style lang="stylus" scoped>
.ossurl {
  overflow-y: auto;
  padding-bottom: 2rem;
}

.content {
  padding: 10px 20px;
  margin: 30px 50px;
  border: 1px dashed rgb(158, 153, 153);
  background-color: rgb(241, 235, 235);
  color: rgb(62, 58, 57);
  border-radius: 20px;

  .item {
    padding: 20px 20px;

    span {
      font-size: 35px;
      font-family: PingFang SC;
    }

    .label {
      font-weight: 600;
    }

    .contwords {
      margin-top: 30px;
      min-height: 100px;
      font-size: 35px;
      line-height: 50px;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: PingFang SC;
    }
  }
}

.btn {
  position: fixed;
  bottom: 0;
  width: 100%;

  .applyBtn {
    text-align: center;
    padding: 20px 30px;
    width: 100%;
    // background-color: color-primary;
    color: #fff;
    border-radius: 10px;
    font-size: 40px;
  }
}

.transmit {
  position: fixed;
  bottom: 700px;
  right: 50px;

  img {
    width: 100px;
    height: 100px;
  }
}

.applyDialog /deep/ .md-dialog-content {
  width: 800px;
}

.red {
  color: red !important;
}

.applyItem {
  border-bottom: 1px solid #e0e0e0;
  margin: 0 auto;
  width: 90%;

  span {
    font-size: 40px;
    line-height: 100px;
    color: #000;
  }

  input {
    width: 60%;
    font-size: 40px;
  }
}

.dialogbottom {
  margin-top: 30px;

  .dialogBtn {
    text-align: center;
    margin: 10px auto;
    padding: 20px 30px;
    width: 50%;
    // background-color: color-primary;
    color: #fff;
    border-radius: 10px;
    font-size: 38px;
  }
}

.applyDialog /deep/ .md-dialog-title {
  font-size: 45px !important;
}

.myApplyMsg {
  padding-bottom: 100px;

  .myMsgTitle {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
  }
}

.share {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  z-index: 999;
  position: fixed;
  top: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
  }
}
</style>
